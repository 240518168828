import { ItemSchema, type Item } from "../../@types/schemas/trolley.js";
import { createSignal } from "solid-js";
import CATEGORY from "../assets/js/category.ts";
import { isEmpty as _isEmpty, startCase as _startCase } from "lodash-es";
// @ts-ignore
import { useStore } from "@nanostores/solid";
import { $currentCategory } from "../stores/category.ts";
import { localItems } from "../assets/js/pouchDB.ts";
import {
	generateItemID,
} from "../assets/js/itemService.ts";
import { $user } from "../stores/user.ts";
import ImageService from "../assets/js/imageService.ts";
import { dispatchForceSyncEvent } from "../assets/js/util.ts";

interface Props {
	category: keyof typeof CATEGORY;
}

export default function ItemInput() {
	const [loading, setLoading] = createSignal(false);
	const [itemName, setItemName] = createSignal("");
	const currentCategory = useStore($currentCategory);

	const addToTrolley = async (event: Event) => {
		event.preventDefault();
		setLoading(true);

		let name = itemName()?.trim();

		if (!_isEmpty(name)) {
			let itemID = generateItemID(name);

			await localItems.upsert(itemID, (existingItem: Item): Item => {
				// new item
				if (Object.keys(existingItem).length === 0) {
					const imageBlob = ImageService.get(name);

					return ItemSchema.parse({
						_id: itemID,
						added_by: $user.get().name,
						category: currentCategory(),
						name,
						_attachments: {
							image: {
								content_type: imageBlob.type,
								data: imageBlob,
							},
						},
					});
				}

				const currentUser = $user.get().name;

				// allow tne name to be updated
				existingItem.name = name;

				// reset added_* when the item already exists
				existingItem.added_on = new Date().toISOString();
				existingItem.added_by = currentUser;

				// set the item amount to 1
				existingItem.amount = 1;

				// increment weight
				existingItem.weight = existingItem.weight + 1;

				existingItem.archived = false;

				existingItem.category = currentCategory();

				return ItemSchema.parse(existingItem);
			});

			navigator?.vibrate(100);

			// cleanup
			setItemName("");

			// sync to server
			dispatchForceSyncEvent();
		}

		setLoading(false);
	};

	return (
		<form class="control is-medium mb-2" onSubmit={addToTrolley}>
			<div class="columns">
				<div class="column">
					<div class="field has-addons">
						<div class="control is-expanded">
							<input
								type="text"
								class="input focus:border-secondary focus:shadow-none"
								value={itemName()}
								spellcheck={true}
								onInput={async (event) => {
									setItemName(event.target.value);
								}}
								placeholder={getItemInputPlaceholder(
									currentCategory(),
								)}
								onFocus={(event) => event.target.select()}
								disabled={loading()}
							/>
						</div>
						<p class="control">
							<button
								type="submit"
								class="button is-warning"
								disabled={!itemName()}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16px"
									fill="black"
									viewBox="0 0 448 512"
								>
									<path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
								</svg>
							</button>
						</p>
					</div>
				</div>
			</div>
		</form>
	);
}

function getItemInputPlaceholder(category: string) {
	switch (category) {
		case CATEGORY.INVENTORY:
			return "What do we have?";
		case CATEGORY.LIMBO:
			return "Where should these go?";
		case CATEGORY.OTHER:
			return "What do we need from other stores?";
		case CATEGORY.DISC:
			return "What movies do we want?";
		case CATEGORY.FOOD:
			return "What food do we need?";
		case CATEGORY.HOME:
			return "What house items do we need?";
		case CATEGORY.HOMESCHOOLING:
			return "What do we need for home schooling?";
		case CATEGORY.TROLLEY:
			return "What do we need?";
		case CATEGORY.THREE_D_PRINT:
			return "What do we need to 3D print?";
		default:
			return `What do we need from ${_startCase(category)}?`;
	}
}
